import {IMergedPick} from "modules/types";
import {hidePicksHelp, updatePick, setBlurPage, ISquad, saveTip} from "modules/actions";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {RadioPick} from "./RadioPick";
import styled, {css} from "styled-components";
import {MatchStatus} from "modules/utils/enums";
import {useDispatch, useSelector} from "react-redux";
import {useMediaQuery} from "modules/utils/hooks";
import {
	getTeamLogoUrl,
	isWBBL,
	REACT_APP_BBL_COMPNAME,
	REACT_APP_WBBL_COMPNAME,
} from "modules/utils/constants";
import {ReactComponent as Cross} from "assets/img/icons/cross.svg";
import {
	getPicksHelp,
	getSquadsById,
	getRoundStatsById,
	getSelectedRoundMatches,
	getVenuesByID,
	getSelectedRoundID,
	getLadderBySquadID,
	getMergedTipsMatchesSelectedRound,
	getRoundBySelectedID,
	getRoundByIdSelector,
	getSquads,
} from "modules/selectors";
import {IStatsButton, IStatsButtonWithSquad, Stats} from "./Stats";
import iconCheck from "assets/img/icons/check.svg";
import iconCheckBlack from "assets/img/icons/checkBlack.svg";
import iconWhiteLock from "assets/img/icons/lockWhite.svg";
import iconWhiteCross from "assets/img/icons/crossWhite.svg";

import {IconFilledDown} from "components/Icons/IconFilledDown";
import {Margin} from "./Margin";
import {Exist, ProgressCircle} from "components";
import {isUndefined, get, isEmpty} from "lodash";
import {theme} from "assets/themes/bbl";
import {ordinal, CURR_SPORT_GOLDEN_WEEK, isAutoPickNotCounted} from "modules/utils/helpers";
import {DateTime} from "luxon";

const getPickClassName = (isChecked: boolean, status: MatchStatus, pickState: string) => {
	let names = pickState;
	if (isChecked) {
		names += " checked";
	}
	if (!isChecked && status === MatchStatus.Active) {
		names += " not-checked-active";
	}

	if (status === MatchStatus.Active) {
		names += " active";
	}

	if (status === MatchStatus.Complete && !isChecked) {
		names += " hidden";
	}
	return names;
};

interface IPickWrapper {
	foreground: boolean;
}

const PickWrapper = styled.div<IPickWrapper>`
	position: relative;
	min-height: 150px;
	box-shadow: 0 0 20px 0 rgba(1, 1, 1, 0.1);
	margin-bottom: 10px;
	background: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	border-radius: 4px;

	&:last-child {
		margin-bottom: 0;
	}

	${(props) =>
		props.foreground &&
		css`
			position: relative;
			z-index: 10;
		`}
	@media screen and (max-width: 639px) {
		min-height: 130px;
	}
`;

const PickHeader = styled.div`
	width: 100%;
	height: 30px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border-bottom: 0;
	font-family: ${theme.font.montserrat};
	font-weight: 600;
	background: ${theme.colors.white};
	color: ${theme.colors.primary};
	font-size: 12px;
	line-height: 15px;
	justify-content: center;
	display: flex;
	align-items: center;
	padding: 0 10px;
	position: relative;
	@media screen and (max-width: 639px) {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	p {
		color: ${theme.colors.darkGrey};
		letter-spacing: 0.5px;
		font-weight: 400;
		margin-left: 3px;
		@media screen and (max-width: 639px) {
			width: 260px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		@media screen and (max-width: 430px) {
			width: 190px;
		}
		@media screen and (max-width: 370px) {
			width: 170px;
		}
		@media screen and (max-width: 370px) {
			width: 170px;
		}
		@media screen and (max-width: 370px) {
			width: 170px;
		}
		@media screen and (max-width: 360px) {
			width: 145px;
		}
	}
`;

const PickFooter = styled.div`
	width: 100%;
	min-height: 25px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border-top: 0;
	background: #f6f6f6;
	display: flex;
	align-items: center;
	flex-direction: column;
	font-family: ${theme.font.halisa};
	font-size: 20px;
	color: ${theme.colors.primary};
	font-weight: bold;
	padding: 20px 0;
	@media (max-width: 640px) {
		font-size: 16px;
	}
`;

const PickContainer = styled.div`
	display: flex;
	background: #fff;
	color: #7b7b7b;
	font-size: 16px;
	padding: 0;
	position: relative;
	width: 100%;
`;

const SquadNameWrapper = styled.span`
	flex-grow: 1;
	align-self: center;
`;

const SquadName = styled.p`
	font-family: "InterFace Corp", sans-serif;
	text-align: left;

	&.reverse {
		text-align: right;
	}
`;

const SquadNameBig = styled(SquadName)`
	color: #010101;
	font-weight: 700;
	font-size: 18px;
	line-height: 20px;
	text-transform: capitalize;
	font-family: ${theme.font.montserrat};
	@media (max-width: 640px) {
		font-size: 10px;
		line-height: 14px;
		font-weight: 600;
	}
	&.reverse {
		display: flex;
		justify-content: flex-end;
	}
`;

const SquadNameInside = styled.p`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;

	@media (max-width: 640px) {
		width: 35px;
	}
`;

const alignRightStyled = "text-align: right;";

const SquadPosition = styled.p<{
	reverse: boolean;
}>`
	font-family: ${theme.font.montserrat};
	font-size: 12px;
	color: ${theme.colors.primary};
	font-weight: 400;
	${({reverse}) => reverse && alignRightStyled};
	@media (max-width: 640px) {
		font-size: 10px;
		font-weight: 400;
	}
`;

const PickedWrapper = styled.span`
	display: block;
`;
const Picked = styled.span<{
	isPlaying: boolean;
	isScheduled: boolean;
}>`
	width: 16px;
	height: 16px;
	display: block;
	border-radius: 50%;
	position: relative;
	margin-right: 5px;
	border: 2px solid #000;
	background-color: #f8f8f8;
	.reversed & {
		margin-left: 5px;
		margin-right: 0;

		@media (min-width: 640px) {
			margin-left: 10px;
		}
	}
	&:hover,
	&:focus {
		background: url(${iconCheckBlack}) center no-repeat transparent;
		background-size: cover;
	}

	&.checked {
		background: url(${iconCheck}) center no-repeat black;
		background-size: cover;
	}
	/* TODO:
		ADD THIS CODE BACK IN, FOR INITIAL UI PURPOSES WILL KEEP IN
		NORMALLY IF THE GAME HAS STARTED AND THEY HAVENT PICKED ANYTHING WE DONT
		DISPLAY THE PICK RADIO
	*/
	/* ${({isScheduled}) =>
		!isScheduled &&
		`
		&:not(.checked){
			display: none;
		}
	`} */

	&.checked.active {
		background: url(${iconWhiteLock}) center no-repeat black;
		background-size: cover;
	}
	&.not-checked-active {
		border: none;
		background: none;
	}

	&.checked.correct-no-count {
		background: url(${iconCheck}) center no-repeat ${theme.colors.darkGrey};
		border: none;
	}

	&.checked.correct {
		background-color: #46b413;
		border: none;
	}

	&.checked.incorrect {
		background: url(${iconWhiteCross}) center no-repeat;
		background-size: 20px 20px;
		background-color: #f63a25;
		border: none;
	}

	@media (min-width: 640px) {
		margin-right: 10px;
		width: 24px;
		height: 24px;
	}
`;

const LogoContainer = styled.div`
	width: 36px;
	height: 36px;
	margin-right: 5px;
	@media (min-width: 640px) {
		width: 63px;
		height: 60px;
	}
	.reversed & {
		margin-left: 5px;
		margin-right: 0;

		@media (min-width: 640px) {
			margin-left: 20px;
		}
	}
`;
const PickImg = styled.img`
	width: 100%;
	height: 100%;
`;

interface ICheckIsSelected {
	squad_id: number;
	squad: number;
}

const checkIsSelected = ({squad_id, squad}: ICheckIsSelected) => squad_id === squad;

const getComStatus = (
	is_selected: boolean,
	is_correct: boolean,
	status: MatchStatus,
	isAutoNotCount: boolean | undefined
) => {
	if (!is_selected) {
		return "";
	} else if (status === MatchStatus.Active) {
		return "locked";
	} else if (isAutoNotCount && status === MatchStatus.Complete) {
		return "correct-no-count";
	} else if (status === MatchStatus.Complete) {
		return is_correct ? "correct" : "incorrect";
	}

	return "selected";
};

interface IProps {
	pick: IMergedPick;
	onPickChange?: () => void;
}

interface IHeader extends IStatsButton {
	pick: IMergedPick;
}

const Header: React.FC<IHeader> = ({pick, toggleStats}) => {
	const {date, venueId} = pick;
	const venues = useSelector(getVenuesByID);
	const venue = get(venues, venueId, "");
	const venueName = get(venue, "name");
	const matchMoment = DateTime.fromISO(date, {zone: "utc"});
	const isComplete = get(pick, "status") === MatchStatus.Complete;
	const isActive = get(pick, "status") === MatchStatus.Active;

	const MobileOrWebDate = matchMoment.toFormat("D h:mm");

	const formattedDate = isComplete ? "Match Complete" : MobileOrWebDate;

	return (
		<PickHeader>
			{!isActive && (
				<React.Fragment>
					{formattedDate} {"-"}
				</React.Fragment>
			)}{" "}
			<p> {venueName}</p>
		</PickHeader>
	);
};

const Footer: React.FC<IStatsButtonWithSquad> = ({
	id,
	away_squad_id,
	home_squad_id,
	toggleStats,
}) => {
	return (
		<PickFooter>
			Recent Form
			<Stats
				id={id}
				away_squad_id={away_squad_id}
				home_squad_id={home_squad_id}
				toggleStats={toggleStats}
			/>
		</PickFooter>
	);
};

const TipIndicatorWrapper = styled.div`
	width: 240px;
	position: absolute;
	left: calc(50% - 120px);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	z-index: 1;
	@media (max-width: 640px) {
		width: 94px;
		left: calc(50% - 47px);
	}
`;

const PercentItem = styled.p<{
	isMajority: boolean;
	reverse: boolean;
}>`
	font-family: ${theme.font.montserrat};
	font-size: 34px;
	color: ${theme.colors.primary};
	width: 80px;
	font-weight: 400;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	${({isMajority}) =>
		isMajority &&
		`
		font-weight: 600;
	`}

	span {
		margin-left: 5px;
		font-size: 14px;
	}
	@media (max-width: 640px) {
		width: 30px;
		font-size: 12px;
		margin-right: 2px;
		${({reverse}) =>
			reverse &&
			`
			margin-right: 0px;
			margin-left: 2px;
		`}
		span {
			font-size: 10px;
			margin-left: 2px;
		}
	}
`;

interface ITipIndProps {
	pickID: number;
	homeSquad: ISquad | undefined;
	awaySquad: ISquad | undefined;
}
const TipIndicator: React.FC<ITipIndProps> = ({pickID, homeSquad, awaySquad}) => {
	const round_stats = useSelector(getRoundStatsById)(pickID);
	const home_squad_percent = get(round_stats, "home_percent", 0);
	const away_squad_percent = get(round_stats, "away_percent", 0);
	const homeSquadColor = get(homeSquad, "colour", "#009FD2");
	const awaySquadColor = get(awaySquad, "colour", "#DE0148");
	const homeColor = isEmpty(homeSquadColor) ? "#009FD2" : homeSquadColor;
	const awayColorHandled = isEmpty(awaySquadColor) ? "#DE0148" : awaySquadColor;
	const homeColorHandled =
		home_squad_percent === 0 || away_squad_percent === 0 ? "#009FD2" : homeColor;
	const isMobile = useMediaQuery("(max-width: 639px)");
	const circleSize = isMobile ? 30 : 60;
	const strokeWidth = isMobile ? 4 : 2;

	const isAwayMajority = away_squad_percent > home_squad_percent;

	return (
		<TipIndicatorWrapper>
			<PercentItem isMajority={!isAwayMajority} reverse={false}>
				{home_squad_percent}
				<span>%</span>
			</PercentItem>
			<ProgressCircle
				size={circleSize}
				progress={away_squad_percent}
				strokeWidth={strokeWidth}
				strokeColorAway={awayColorHandled}
				strokeColorHome={homeColorHandled}
			/>

			<PercentItem isMajority={isAwayMajority} reverse={true}>
				{away_squad_percent}
				<span>%</span>
			</PercentItem>
		</TipIndicatorWrapper>
	);
};

interface IFinalScoreProps {
	homeScore: {
		runs: number | null;
		wickets: number | null;
	};
	awayScore: {
		runs: number | null;
		wickets: number | null;
	};
	status: MatchStatus;
	pick: IMergedPick;
}

const FinalScoreWrapper = styled(TipIndicatorWrapper)``;

const MatchLiveWrapper = styled(TipIndicatorWrapper)`
	flex-direction: column;
	justify-content: center;
	gap: 3px;
`;

const StyledFinalScore = styled.div<{
	isWinner: boolean;
}>`
	font-family: ${theme.font.montserrat};
	font-size: 34px;
	color: ${theme.colors.primary};
	line-height: 40px;
	font-weight: ${({isWinner}) => (isWinner ? "600" : "400")};
	width: 95px;
	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: 639px) {
		font-size: 14px;
	}
`;

const MatchIsLive = styled.div`
	width: 105px;
	height: 20px;
	background: ${theme.results.correct};
	display: flex;
	justify-content: center;
	border-radius: 4px;
	align-items: center;
	font-family: ${theme.font.montserrat};
	font-size: 12px;
	font-weight: 600;
	line-height: 13px;
	color: #ffffff;
	@media (max-width: 639px) {
		font-size: 10px;
		width: 95px;
	}
`;

const LiveScoreLink = styled.a`
	display: block;
	width: 105px;
	height: 20px;
	background: ${theme.results.correct};
	display: flex;
	justify-content: center;
	border-radius: 4px;
	align-items: center;
	font-family: ${theme.font.montserrat};
	font-size: 12px;
	font-weight: 600;
	line-height: 13px;
	color: #ffffff;
	@media (max-width: 639px) {
		font-size: 10px;
		width: 95px;
	}
`;

const returnWinner = (
	pickedSquad: number,
	homeSquad: number,
	awaySquad: number,
	isCorrect: boolean | undefined
) => {
	const picked = pickedSquad === homeSquad ? homeSquad : awaySquad;
	const notPicked = pickedSquad === homeSquad ? awaySquad : homeSquad;
	return isCorrect ? picked : notPicked;
};

// eslint-disable-next-line complexity
const FinalScore: React.FC<IFinalScoreProps> = ({homeScore, awayScore, status, pick}) => {
	const currentURL = window.location.href;
	const matches = useSelector(getSelectedRoundMatches);
	const squads = useSelector(getSquads);
	const matchID = pick.id;
	const homeTeam = squads.find((squad) => squad.id === pick.homeSquadId);
	const awayTeam = squads.find((squad) => squad.id === pick.awaySquadId);
	const homeTeamStr = (homeTeam?.name || "").toLowerCase().split(" ").join("-");
	const awayTeamStr = (awayTeam?.name || "").toLowerCase().split(" ").join("-");
	const compName = isWBBL(currentURL) ? REACT_APP_WBBL_COMPNAME : REACT_APP_BBL_COMPNAME;
	const liveScoreUrl = `https://www.cricket.com.au/matches/CA:${matchID}/${homeTeamStr}-${awayTeamStr}-${compName}`;
	if (status === MatchStatus.Active || status === MatchStatus.Complete) {
		return (
			<MatchLiveWrapper>
				{status === MatchStatus.Active && <MatchIsLive>MATCH IS LIVE</MatchIsLive>}
				<LiveScoreLink href={`${liveScoreUrl}`} target="_blank" rel="noopener noreferrer">
					Match Centre
				</LiveScoreLink>
			</MatchLiveWrapper>
		);
	}

	if (!matches || !homeScore || !awayScore) {
		return null;
	}
	const winnerID = returnWinner(pick.squad, pick.homeSquadId, pick.awaySquadId, pick.isCorrect);
	const isHomeWinner = winnerID === pick.homeSquadId;

	return (
		<FinalScoreWrapper>
			<StyledFinalScore isWinner={isHomeWinner}>
				{homeScore.wickets}/{homeScore.runs}
			</StyledFinalScore>
			<StyledFinalScore isWinner={!isHomeWinner}>
				{awayScore.wickets}/{awayScore.runs}
			</StyledFinalScore>
		</FinalScoreWrapper>
	);
};

const TipsWrapper = styled.div`
	width: 222px;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
	background: #fff;
	position: absolute;
	z-index: 50;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	margin-bottom: 10px;
	font-family: Arial, sans-serif;
	font-size: 12px;
	line-height: 20px;
	text-align: center;

	&:before {
		content: "";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 10px 10px 0 10px;
		border-color: #fff transparent transparent transparent;
		position: absolute;
		bottom: -10px;
		left: 50%;
		transform: translateX(-50%);
	}
`;

const TipsClose = styled.button`
	position: absolute;
	top: 5px;
	right: 5px;
	padding: 0;
	margin: 0;
	background: none;
	border: 0;
	width: 20px;
	height: 20px;

	svg {
		width: 100%;
		height: 100%;
	}
`;

const Tips: React.FC = () => {
	const dispatch = useDispatch();

	const close = useCallback(() => {
		dispatch(hidePicksHelp());
	}, [dispatch]);

	return (
		<TipsWrapper>
			<TipsClose onClick={close}>
				<Cross />
			</TipsClose>
			<div>Tips</div>
		</TipsWrapper>
	);
};

interface IPickItems {
	id: number;
	reverse?: boolean;

	isPicked: boolean;
	status: MatchStatus;
	pickState: string;
}

const PickItems: React.FC<IPickItems> = ({id, reverse = false, isPicked, status, pickState}) => {
	const isMobile = useMediaQuery("(max-width: 640px)");
	const squad = useSelector(getSquadsById)(id);
	const shortName = get(squad, "shortName", "");
	const logo = getTeamLogoUrl(shortName);
	const name = squad?.name.replace("Women", "");
	const displayName = isMobile ? squad?.shortName : squad?.nickname;
	const reverse_class_name = reverse ? "reverse" : undefined;
	const ladderTeamData = useSelector(getLadderBySquadID)[id];
	const ladderPosition = get(ladderTeamData, "position", 0);
	const handledPosition = ordinal(ladderPosition);
	const showHandledPosition = handledPosition === "0th" ? "" : handledPosition;
	const isScheduled = status === MatchStatus.Scheduled;
	const isPlaying = status === MatchStatus.Active;

	const pickedClass = getPickClassName(isPicked, status, pickState);

	return (
		<Fragment>
			<PickedWrapper tabIndex={0}>
				<Picked isPlaying={isPlaying} isScheduled={isScheduled} className={pickedClass} />
			</PickedWrapper>
			<LogoContainer className={reverse_class_name}>
				{logo && <PickImg src={logo} alt={name} />}
			</LogoContainer>
			<SquadNameWrapper>
				<SquadNameBig className={reverse_class_name}>
					<SquadNameInside>{displayName}</SquadNameInside>
				</SquadNameBig>
				<SquadPosition reverse={reverse}>{showHandledPosition}</SquadPosition>
			</SquadNameWrapper>
		</Fragment>
	);
};

// eslint-disable-next-line complexity
const PickContent: React.FC<IProps> = ({pick, onPickChange}) => {
	const {
		homeSquadId,
		awaySquadId,
		squad,
		status,
		id,
		homeRuns,
		awayRuns,
		homeWicketsFallen,
		awayWicketsFallen,
		margin,
		isCorrect,
	} = pick;
	const round = useSelector(getRoundBySelectedID);
	const picksFromRound = useSelector(getMergedTipsMatchesSelectedRound(round));
	const isAutoNotCount = isAutoPickNotCounted(picksFromRound, pick.match);

	const isCorrectState = isCorrect || false;
	const homeScore = {runs: homeRuns, wickets: homeWicketsFallen};
	const awayScore = {runs: awayRuns, wickets: awayWicketsFallen};

	const isScheduled = status === MatchStatus.Scheduled;
	const dispatch = useDispatch();
	const picksHelp = useSelector(getPicksHelp);
	const is_show_picks_help = picksHelp.show;

	const onChange = useCallback(
		(e: React.SyntheticEvent<HTMLInputElement>) => {
			if (is_show_picks_help) {
				dispatch(hidePicksHelp());
			}
			if (isScheduled) {
				const squadID = e.currentTarget.value;
				dispatch(
					updatePick({
						squad: parseInt(squadID, 10),
						match: id,
						margin,
					})
				);
				if (onPickChange) {
					onPickChange();
				}
				dispatch(saveTip());
			}
		},
		[dispatch, id, isScheduled, is_show_picks_help, onPickChange, margin]
	);

	const isHomeSelected = checkIsSelected({
		squad_id: homeSquadId,
		squad,
	});
	const isAwaySelected = checkIsSelected({
		squad_id: awaySquadId,
		squad,
	});
	const homePickState = getComStatus(isHomeSelected, isCorrectState, status, isAutoNotCount);
	const awayPickState = getComStatus(isAwaySelected, isCorrectState, status, isAutoNotCount);

	const homeSquad = useSelector(getSquadsById)(homeSquadId);
	const awaySquad = useSelector(getSquadsById)(awaySquadId);

	const homeDataAttr = {
		"data-category": " picks",
		"data-action": " take your pick click",
		"data-label": homeSquad?.name || "",
	};
	const awaySquadAttr = {
		"data-category": " picks",
		"data-action": " take your pick click",
		"data-label": awaySquad?.name || "",
	};

	const isCheckedHome = homeSquadId === squad;
	const isCheckedAway = awaySquadId === squad;

	return (
		<PickContainer>
			<RadioPick
				disabled={!isScheduled}
				name={`${pick.id}-home-${homeSquadId}`}
				form_value={squad}
				value={homeSquadId}
				status={status}
				onChange={onChange}
				pickState={homePickState}
				dataAttr={homeDataAttr}>
				<PickItems
					id={homeSquadId}
					status={pick.status}
					isPicked={isCheckedHome}
					pickState={homePickState}
				/>
			</RadioPick>
			{isScheduled ? (
				<TipIndicator pickID={id} homeSquad={homeSquad} awaySquad={awaySquad} />
			) : (
				<FinalScore
					homeScore={homeScore}
					awayScore={awayScore}
					status={status}
					pick={pick}
				/>
			)}
			<RadioPick
				reverse={true}
				disabled={!isScheduled}
				name={`${pick.id}-away-${awaySquadId}`}
				form_value={squad}
				value={awaySquadId}
				status={status}
				onChange={onChange}
				pickState={awayPickState}
				dataAttr={awaySquadAttr}>
				<PickItems
					id={awaySquadId}
					reverse={true}
					isPicked={isCheckedAway}
					status={pick.status}
					pickState={awayPickState}
				/>
			</RadioPick>
		</PickContainer>
	);
};

// const isShowError = (showErrorForEmpty: boolean, squad: number, isScheduled: boolean) => {
// 	return showErrorForEmpty && !squad && isScheduled;
// };

const FormGuideWrapper = styled.button<{
	isOpen: boolean;
}>`
	width: 160px;
	height: 30px;
	outline: none;
	background: #f6f6f6;
	display: flex;
	justify-content: center;
	align-items: center;
	clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
	border: none;
	cursor: pointer;
	position: relative;
	svg {
		${({isOpen}) =>
			isOpen &&
			`
			transform: rotate(180deg);
		`}
		margin-left: 4px;
		margin-top: 2px;
		pointer-events: none;
	}
`;

const FormButtonText = styled.p`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	font-family: ${theme.font.halisa};
	font-weight: bold;
	font-size: 12px;
	color: ${theme.colors.darkGrey};
`;

const MarginHeader = styled.div<{
	isGoldenWeek: boolean;
	isWBBL: boolean;
}>`
	width: 100%;
	border-radius: 5px 5px 0 0;
	background: linear-gradient(
		90deg,
		rgba(50, 53, 216, 255) 0%,
		rgba(94, 39, 170, 255) 25%,
		rgba(158, 20, 106, 255) 75%,
		rgba(208, 4, 53, 255) 100%
	);
	${({isWBBL}) =>
		isWBBL &&
		`
		background: linear-gradient(
			90deg,
			rgba(165,25,190,255) 0%,
			rgba(185,45,160,255) 25%,
			rgba(220,77,109,255) 75%,
			rgba(247,102,67,255) 100%
		);
	`}
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: ${theme.font.halisa};
	font-size: 16px;
	font-weight: 700;
	color: #fff;
	${({isGoldenWeek}) =>
		isGoldenWeek &&
		`
		color: ${theme.colors.gold};
	`}
`;

const getHeaderCopy = (isGoldenWeek: boolean, currentSportGoldenWeek: number) => {
	if (isGoldenWeek) {
		return `Week ${currentSportGoldenWeek} – DOUBLE POINTS`;
	} else {
		return "This week's Super Sixes game";
	}
};

// eslint-disable-next-line complexity
export const Pick: React.FC<IProps> = ({pick, onPickChange}) => {
	const [showForm, setShowForm] = useState(false);
	const {id, homeSquadId, awaySquadId, margin, squad, isMarginGame, status} = pick;

	const dispatch = useDispatch();
	const picksHelp = useSelector(getPicksHelp);
	const selectedRoundId = useSelector(getSelectedRoundID);
	const selectedRound = useSelector(getRoundByIdSelector)(selectedRoundId);
	const selectedRoundNumber = get(selectedRound, "number", 1);

	const is_current_picks_help = picksHelp.pickId === id;
	const is_show_picks_help = picksHelp.show;
	const show_tip = is_show_picks_help && is_current_picks_help;
	const currentURL = window.location.href;
	const showWBBL = isWBBL(currentURL);

	const [is_full_stats_open, setFullStatsOpen] = useState(false);

	const toggleStats = useCallback(() => {
		setFullStatsOpen(!is_full_stats_open);
	}, [setFullStatsOpen, is_full_stats_open]);

	useEffect(() => {
		if (!is_show_picks_help) {
			dispatch(setBlurPage(false));
			return;
		}
		if (is_current_picks_help) {
			dispatch(setBlurPage(is_show_picks_help));
		}
	}, [dispatch, is_current_picks_help, is_show_picks_help]);

	const isMargin = isMarginGame && !isUndefined(margin);
	const isScheduled = status === MatchStatus.Scheduled;
	const isGoldenWeek = selectedRoundNumber === Number(CURR_SPORT_GOLDEN_WEEK);

	return (
		<PickWrapper foreground={show_tip}>
			<Exist when={isMargin}>
				<MarginHeader isGoldenWeek={isGoldenWeek} isWBBL={showWBBL}>
					{getHeaderCopy(isGoldenWeek, Number(CURR_SPORT_GOLDEN_WEEK))}
				</MarginHeader>
			</Exist>
			{show_tip && <Tips />}
			<Header pick={pick} toggleStats={toggleStats} />
			<PickContent pick={pick} onPickChange={onPickChange} />
			<Exist when={isMargin}>
				<Margin
					isScheduled={isScheduled}
					squadId={squad}
					pick={pick}
					predictMargin={margin}
				/>
			</Exist>

			<FormGuideWrapper isOpen={showForm} onClick={() => setShowForm(!showForm)}>
				<FormButtonText>FORM GUIDE</FormButtonText>
				<IconFilledDown />
			</FormGuideWrapper>

			{showForm ? (
				<Footer
					id={id}
					away_squad_id={awaySquadId}
					home_squad_id={homeSquadId}
					toggleStats={() => setShowForm(!showForm)}
				/>
			) : null}
		</PickWrapper>
	);
};
