import React, {useCallback, useState, useRef, useEffect} from "react";
import styled from "styled-components";
import {get} from "lodash";
import {IconArrowRight} from "components/Icons/IconArrowRight";
// import {Exist} from "components";
import {useDispatch, useSelector} from "react-redux";
import {changeSelectedRound} from "modules/actions";
import {getSportName, CURR_SPORT_GOLDEN_WEEK} from "modules/utils/helpers";
import {
	getFirstRoundId,
	getLastRoundId,
	getSelectedRoundID,
	getRoundByIdSelector,
	getRounds,
} from "modules/selectors";
import {IRound} from "modules/types";
import {Exist} from "components/Exist";
import {DateTime} from "luxon";

const RoundsSelectorWrapper = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	position: relative;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	min-width: 240px;
	.rotate {
		transform: rotate(180deg);
	}
	&:focus {
		border: 1px solid black;
	}
	@media (max-width: 640px) {
		min-width: unset;
		width: 100%;
		svg {
			float: right;
			width: 90px;
		}
		.bkt-sponsor {
			margin-top: -15px;
		}
		max-height: 40px;
	}
`;

const RoundsSelectorButton = styled.button`
	width: 40px;
	height: 40px;
	background: white;
	outline: none;
	border: 1px solid #eaeaea;
	border-radius: 2px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	&:disabled {
		background: transparent;
		cursor: no-drop;
		polygon {
			fill: #b8b8b8;
		}
	}
	&:focus {
		border: 1px solid black;
	}

	polygon {
		fill: #000000;
	}
`;

const Value = styled.button<{
	isGoldenWeek: boolean;
}>`
	font-size: 14px;
	line-height: 14px;
	cursor: pointer;
	font-weight: 600;
	// position: relative;
	white-space: nowrap;
	font-family: "Montserrat", sans-serif;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 35px;
	justify-content: center;
	border: none;
	outline: none;
	background: transparent;
	color: white;

	${({isGoldenWeek}) =>
		isGoldenWeek &&
		`
		color: rgb(212, 175, 55);

	`};
	span {
		font-size: 12px;
		font-weight: 600;
		color: #b8b8b8;
		margin-top: 5px;
		text-align: center;
		${({isGoldenWeek}) =>
			isGoldenWeek &&
			`
			font-weight: 800;
			`}
	}
	&:hover {
		.round-options {
			display: flex;
			z-index: 2;
		}
	}

	@media (min-width: 640px) {
		font-size: 18px;
	}
	@media (max-width: 640px) {
		font-size: 12px;
	}
`;

const RoundOptionsWrapper = styled.div<{
	isShow: boolean;
	isBBL: boolean;
}>`
	display: none;
	position: absolute;
	border: 1px solid black;
	border-radius: 2px;
	top: 41px;
	left: 0px;
	box-shadow: 0px 0px 20px 0 rgba(1, 1, 1, 0.1);
	padding: 25px 15px;
	flex-wrap: wrap;
	flex-direction: row;
	min-width: 260px;
	align-items: center;
	z-index: 10;
	background: white;
	&:hover {
		display: flex;
	}
	&:before {
		content: "";
		width: 0;
		position: absolute;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		left: calc(50% - 2.5px);
		top: -5px;
		border-bottom: 5px solid white;
	}
	@media screen and (max-width: 639px) {
		width: 320px;
		height: 145px;
		left: 0;
	}
	${({isShow}) =>
		isShow &&
		`
		display: flex;
	`}
	${({isBBL}) =>
		isBBL &&
		`
		min-width: 300px;
	`}
`;

// const BlackSpan = styled.span`
// 	color: black;
// `;

const RoundButton = styled.button<{
	isActive: boolean;
	isGoldenWeek: boolean;
}>`
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	border: 1px solid black;
	color: black;
	font-size: 14px;
	font-family: "Halisa";
	font-weight: 700;
	background: white;
	margin: 4px;

	${({isGoldenWeek}) =>
		isGoldenWeek &&
		`
		border: 1px solid #D4AF37;

	`};

	${({isActive}) =>
		isActive &&
		`
		background: black;
		color: #e6ff00;
	`};

	${({isGoldenWeek, isActive}) =>
		isGoldenWeek &&
		isActive &&
		`
		background: #D4AF37;
		border: 1px solid #D4AF37;
		color: black;
	`};

	@media screen and (max-width: 639px) {
		width: 48px;
		height: 48px;
	}
`;

const getWeekFormatSpan = (round: IRound) => {
	const startDate = get(round, "startDate");
	const endDate = get(round, "endDate");
	const roundStart = DateTime.fromISO(String(startDate), {zone: "utc"});
	const roundEnd = DateTime.fromISO(String(endDate), {zone: "utc"});
	return `${roundStart.toFormat("ccc d LLL")} – ${roundEnd.toFormat("ccc d LLL")}`;
};

const filterOneMatchCompleteRounds = (rounds: IRound[]) => {
	return rounds.filter((round) => {
		const {matches} = round;
		if (round.number === 1) {
			return true;
		}
		return matches.filter((match) => match.status === "complete").length > 0;
	});
};

interface IProps {
	isLadder?: boolean;
	isGoldenWeek?: boolean;
}

export const TippingRoundsSelector: React.FC<IProps> = ({isLadder, isGoldenWeek}) => {
	const ref = useRef<HTMLDivElement>(null);
	const dispatch = useDispatch();
	const [showRounds, setShowRounds] = useState(false);
	const selectedRoundId = useSelector(getSelectedRoundID);
	const sport = getSportName();
	const isBBL = sport === "bbl";

	const firstRoundId = useSelector(getFirstRoundId);
	const lastRoundId = useSelector(getLastRoundId);
	const roundById = useSelector(getRoundByIdSelector)(selectedRoundId);
	const displayWeek = get(roundById, "number") || 0;
	const roundsArr = useSelector(getRounds);

	const roundsHandled = isLadder ? filterOneMatchCompleteRounds(roundsArr) : roundsArr;

	const roundsLength = roundsHandled.length;

	const firstAllowedRound = Number(get(roundsHandled, "[0].id"));
	const maxIndex = roundsLength - 1;
	const maxAllowedRound = Number(get(roundsHandled, `[${maxIndex}].id`));
	const firstRoundHandled = isLadder ? firstAllowedRound : firstRoundId;
	const lastRoundHandled = isLadder ? maxAllowedRound : lastRoundId;

	useEffect(() => {
		if (isLadder) {
			const roundToChange =
				selectedRoundId <= maxAllowedRound ? selectedRoundId : maxAllowedRound;
			dispatch(changeSelectedRound({round: roundToChange}));
		}
		const checkIfClickedOutside = (e: Event) => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (showRounds && ref.current && !ref.current.contains(e.target as Node)) {
				setShowRounds(false);
			}
		};
		document.addEventListener("mousedown", checkIfClickedOutside);
		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", checkIfClickedOutside);
		};
	}, [
		showRounds,
		isLadder,
		dispatch,
		roundsHandled,
		roundsLength,
		selectedRoundId,
		maxAllowedRound,
	]);

	const changeRound = useCallback(
		(e: React.SyntheticEvent<HTMLButtonElement>) => {
			const roundId = Number(e.currentTarget.value);
			dispatch(changeSelectedRound({round: roundId}));
			setShowRounds(false);
		},
		[dispatch]
	);

	const handleShowRounds = () => {
		setShowRounds(!showRounds);
	};
	// const openShowRounds = () => {
	// 	setShowRounds(true);
	// };

	if (!roundById) {
		return null;
	}
	const roundFormat = getWeekFormatSpan(roundById);

	const prevId = selectedRoundId - 1;
	const nextId = selectedRoundId + 1;
	const prevDisabled = prevId === firstRoundHandled - 1;
	const nextDisabled = nextId === lastRoundHandled + 1;

	return (
		<React.Fragment>
			<RoundsSelectorWrapper ref={ref}>
				<RoundsSelectorButton
					onClick={changeRound}
					value={prevId}
					disabled={prevDisabled}
					className={"rotate"}>
					<IconArrowRight color={"#fff"} />
				</RoundsSelectorButton>
				<Value
					onClick={handleShowRounds}
					className="round-value"
					isGoldenWeek={Boolean(isGoldenWeek)}>
					{displayWeek === CURR_SPORT_GOLDEN_WEEK
						? `BKT GOLDEN WEEK`
						: `Week ${displayWeek}`}
					<span>{roundFormat}</span>
					<Exist when={showRounds}>
						<RoundOptionsWrapper
							className="round-options"
							isShow={showRounds}
							isBBL={isBBL}>
							{roundsHandled.map((round, index) => {
								const isActive = selectedRoundId === round.id;
								const isGoldenWeek =
									round.number === Number(CURR_SPORT_GOLDEN_WEEK);
								return (
									<RoundButton
										key={`${index}-round`}
										value={round.id}
										onClick={changeRound}
										isGoldenWeek={isGoldenWeek}
										isActive={isActive}>
										{round.number}
									</RoundButton>
								);
							})}
						</RoundOptionsWrapper>
					</Exist>
				</Value>

				<RoundsSelectorButton onClick={changeRound} value={nextId} disabled={nextDisabled}>
					<IconArrowRight color={"#fff"} />
				</RoundsSelectorButton>
			</RoundsSelectorWrapper>
		</React.Fragment>
	);
};
